import dynamic from 'next/dynamic'
import Head from 'next/head'
import MainLayout from '../components/global/layouts/MainLayout'
import useNostoBuilderPage from '../lib/clientOnly/nosto/useNostoBuilderPage'
import COMPANY_INFO from '../lib/constants/COMPANY_INFO'
import { builder_getServerSideProps } from '../lib/serverOnly/pageTypes/builder/builder_getServerSideProps'

const BuilderPage = dynamic(import('../components/pageType/builder/BuilderPage'))

export default function BuilderPages(props) {
  useNostoBuilderPage(props.isHome)

  const pageData = props?.content?.data
  return (
    <>
      <Head>
        <title>{pageData?.title || COMPANY_INFO.name}</title>

        <meta name="description" content={pageData?.description || ''} />
        <meta name="robots" content={pageData?.indexable === false ? 'noindex, nofollow' : 'all'} />
        {pageData?.ogImage && <meta property="og:image" content={pageData?.ogImage} />}
        <meta
          property="og:title"
          content={pageData?.ogTitle ? pageData?.ogTitle : pageData?.title || ''}
        />
        <meta
          property="og:description"
          content={pageData?.ogDescription ? pageData?.ogDescription : pageData?.description || ''}
        />
        {pageData?.jsonld && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: pageData?.jsonld,
            }}
          />
        )}
        <link
          rel="canonical"
          href={`${COMPANY_INFO.storefrontUrl}${pageData?.canonicalUrl || pageData?.url || ''}`}
        />
      </Head>
      <MainLayout staticProps={props.layoutStaticProps} useBodyPadding={false}>
        <BuilderPage props={props} />
      </MainLayout>
    </>
  )
}

export const getServerSideProps = builder_getServerSideProps
